import React, { useEffect } from 'react';
import {List,ListItem,Grid} from '@mui/material'
import FormattedTextField from '../Formatted/FormattedTextField';
import moment from 'moment';
import PropTypes from 'prop-types'
import {fonts} from '../../util/Theme'
import { dateStringWithoutYear } from '../../util/generalHelp';
/**
 * component used to represent all hour fields for a given timesheet
 * @component
 * @category Timesheets
 * @subcategory Timesheet Form
 */
function DocusignTSFields(props) {
  const defaultDayDisabled = () => {return false}
  const isDayDisabled = props.isDayDisabled || defaultDayDisabled

  function getBackgroundColor(date){
    if(props.getBackgroundColor)
      return props.getBackgroundColor(props.timesheets[date])
    
    return (props.beenSubmitted == true || props.timesheets[date].submitted ) ? "lightgrey" : "white"
  }

  const outOfBoundary = (date) =>{
    if(!props.boundaryDates) return false

    let d = moment.utc(date)
    //console.log('testing',date,props.boundaryDates)

    if(props.boundaryDates.startDates && props.boundaryDates.startDates.length > 0){
      for(let start of props.boundaryDates.startDates){
        if(d.isBefore(moment.utc(start))) return true
      }
    }

    if(props.boundaryDates.endDates && props.boundaryDates.endDates.length > 0){
      for(let end of props.boundaryDates.endDates){
        if(d.isAfter(moment.utc(end))) return true
      }
    }
    
    return false
  }

    return (
        <List
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexFlow: "row wrap",
                  width:'100%'
                }}
              >
                {Object.keys(props.timesheets).map((date, index) => (
                  <Grid item xs={12/props.daysPerRow}>
                    <ListItem key={index.toString()}>
                      <FormattedTextField
                        fullWidth
                        type="text"
                        step="0.01"
                        value={String(props.timesheets[date].hours)}
                        placeholder={String(props.timesheets[date].hours)}
                        disabled={props.beenSubmitted || props.timesheets[date].submitted || outOfBoundary(date) || isDayDisabled(date)}
                        key={String(index)}
                        InputLabelProps={{
                          style: { 
                            fontSize: fonts.labelSize,
                            color: isDayDisabled(date) ? "#757575" : getBackgroundColor(date) 
                          },
                        }}
                        variant="filled"
                        label={dateStringWithoutYear(
                          moment(date).toDate().toDateString()
                        )}
                        onChange={(event) => {
                          event.preventDefault();
                          if(props.updateHours){
                            props.updateHours(event.target.value, date, index);
                          }
                        }}
                        autoFocus={index === 0}
                        error={props.errorIndices ? props.errorIndices.includes(index) : false}
                        helperText={
                          props.errorIndices && props.errorIndices.includes(index)
                            ? "Please enter a number in between 0 and 24"
                            : ""
                        }
                        onKeyDown={props.onDown ? props.onDown : () => {}}
                        sx={{
                          backgroundColor: isDayDisabled(date) ? "#f5f5f5" : undefined
                        }}
                      />
                    </ListItem>
                  </Grid>
                ))}
                <br />
                <br />
              </List>
    );
}

DocusignTSFields.propTypes = {
  /**
   * function triggered when key is pressed while focus on a field, passes event e
   */
    onDown: PropTypes.func,
    /**
     * function called when hours value in a field is chaned, passes new value, date, index
     */
    updateHours: PropTypes.func,
    /**
     * object representing the timesheet that the field's form is under
     */
    timesheets: PropTypes.object.isRequired,
    /**
     * array of indices that have an invalid value in them or an error for them
     */
    errorIndices: PropTypes.arrayOf(PropTypes.number),
    /**
     * boolean indicating whether or not the timesheet has already been submitted
     */
    beenSubmitted: PropTypes.bool,
    /**
     * number of days per row for the form to generate
     */
    daysPerRow: PropTypes.number,
    /**
     * object used to indicate start and end dates of project / resource's term on said project
     * follows {startDates : [array of dates], endDates: [
     */
    boundaryDates: PropTypes.object,
    /**
     * function that determines if a day is disabled
     */
    isDayDisabled: PropTypes.func,
}

DocusignTSFields.defaultProps = {
    beenSubmitted: false,
    daysPerRow: 7,
    isDayDisabled: (date) => {return false}
}

export default DocusignTSFields;
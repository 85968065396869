import React, { useEffect, useState } from "react";
import { List, ListItem, Grid, Typography, Card, CardContent, InputLabel } from "@mui/material";
import FormattedTextField from "../Formatted/FormattedTextField";
import moment from "moment";
import PropTypes from "prop-types";
import { fonts } from "../../util/Theme";
import { dateStringWithoutYear } from "../../util/generalHelp";
/**
 * component used to represent all hour fields for a given timesheet
 * @component
 * @category Timesheets
 * @subcategory Timesheet Form
 */
function TimeSheetFields(props) {

  function getBackgroundColor(date){
    if(props.getBackgroundColor)
      return props.getBackgroundColor(props.timesheets[date])
    
    return (props.beenSubmitted == true || props.timesheets[date].submitted ) ? "lightgrey" : "white"
  }

  const outOfBoundary = (date) => {
    if (!props.boundaryDates) return false;

    let d = moment.utc(date);
    //console.log('testing',date,props.boundaryDates)

    if (
      props.boundaryDates.startDates &&
      props.boundaryDates.startDates.length > 0
    ) {
      for (let start of props.boundaryDates.startDates) {
        if (d.isBefore(moment.utc(start))) return true;
      }
    }
    // if(props.boundaryDates.endDates && props.boundaryDates.endDates.length > 0){
    //   for(let end of props.boundaryDates.endDates){
    //     if(d.isAfter(moment.utc(end))) return true
    //   }
    // }
    return false;
  };

  return (
    <List
      sx={{
        display: "flex",
        flexDirection: "row",
        flexFlow: "row wrap",
        width: "100%",
      }}
    >
      {Object.keys(props.timesheets).map((date, index) => (
        <Grid key = {index} item xs={12 / props.daysPerRow}>
          <ListItem key={index.toString()}>
            <Card style={{background: props.isDayDisabled(date) ? "#f5f5f5" : getBackgroundColor(date)}}>
              <CardContent>
            <Grid container columns={1}>
              <Grid item xs ={1}>
                <Typography sx={{marginBottom: 2, marginTop:0, color: props.isDayDisabled(date) ? "#757575" : "black"}}>{dateStringWithoutYear(
                    moment(date).toDate().toDateString())}</Typography>
              </Grid>
              <Grid item xs={1}>
                <FormattedTextField
                  fullWidth
                  type="text"
                  step="0.01"
                  value={String(props.timesheets[date].hours)}
                  placeholder={String(props.timesheets[date].hours)}
                  disabled={props.beenSubmitted || props.timesheets[date].submitted || outOfBoundary(date) || props.isDayDisabled(date)}
                  key={String(index)}
                  InputLabelProps={{
                    style: { fontSize: fonts.labelSize },
                  }}
                  variant="standard"
                  onChange={(event) => {
                    event.preventDefault();
                    if (props.updateHours) {
                      props.updateHours(event.target.value, date,"hours", index);
                    }
                  }}
                  autoFocus={index === 0}
                  error={
                    props.errorIndices
                      ? props.errorIndices.includes(index)
                      : false
                  }
                  helperText={
                    props.errorIndices && props.errorIndices.includes(index)
                      ? "Please enter a number in between 0 and 24"
                      : ""
                  }
                  onKeyDown={props.onDown ? props.onDown : () => {}}
                />
              </Grid>
              <InputLabel sx={{fontSize:'12px', marginBottom:'5px'}}>Standard</InputLabel>
              <Grid item xs={1}>
              <FormattedTextField
                  fullWidth
                  type="text"
                  step="0.01"
                  value={String(props.timesheets[date].holiday_hours)}
                  placeholder={String(props.timesheets[date].holiday_hours)}
                  disabled={props.beenSubmitted || props.timesheets[date].submitted || outOfBoundary(date) || props.isDayDisabled(date)}
                  key={String(index)}
                  InputLabelProps={{
                    style: { fontSize: fonts.labelSize },
                  }}
                  variant="standard"
                  
                  onChange={(event) => {
                    event.preventDefault();
                    if (props.updateHours) {
                      props.updateHours(event.target.value, date,"holiday" ,index);
                    }
                  }}
                  autoFocus={index === 0}
                  error={
                    props.errorIndices
                      ? props.errorIndices.includes(index)
                      : false
                  }
                  helperText={
                    props.errorIndices && props.errorIndices.includes(index)
                      ? "Please enter a number in between 0 and 24"
                      : ""
                  }
                  onKeyDown={props.onDown ? props.onDown : () => {}}
                />
              </Grid>
              <InputLabel sx={{fontSize:'12px', marginBottom:'5px'}}>Holiday</InputLabel>
              <Grid item xs={1}>
              <FormattedTextField
                  fullWidth
                  type="text"
                  step="0.01"
                  value={String(props.timesheets[date].vacation_hours)}
                  placeholder={String(props.timesheets[date].vacation_hours)}
                  disabled={props.beenSubmitted || props.timesheets[date].submitted || outOfBoundary(date) || props.isDayDisabled(date)}
                  key={String(index)}
                  InputLabelProps={{
                    style: { fontSize: fonts.labelSize },
                  }}
                  variant="standard"
                  
                  onChange={(event) => {
                    event.preventDefault();
                    if (props.updateHours) {
                      props.updateHours(event.target.value, date, "vacation", index);
                    }
                  }}
                  autoFocus={index === 0}
                  error={
                    props.errorIndices
                      ? props.errorIndices.includes(index)
                      : false
                  }
                  helperText={
                    props.errorIndices && props.errorIndices.includes(index)
                      ? "Please enter a number in between 0 and 24"
                      : ""
                  }
                  onKeyDown={props.onDown ? props.onDown : () => {}}
                />
              </Grid>
              <InputLabel sx={{fontSize:'12px', marginBottom:'5px'}}>Vacation</InputLabel>
              <Grid item xs={1}>
                <FormattedTextField
                  fullWidth
                  type="text"
                  step="0.01"
                  value={String(props.timesheets[date].sick_hours)}
                  placeholder={String(props.timesheets[date].sick_hours)}
                  disabled={props.beenSubmitted || props.timesheets[date].submitted || outOfBoundary(date) || props.isDayDisabled(date)}
                  key={String(index)}
                  InputLabelProps={{
                    style: { fontSize: fonts.labelSize },
                  }}
                  variant="standard"
                  
                  onChange={(event) => {
                    event.preventDefault();
                    if (props.updateHours) {
                      props.updateHours(event.target.value, date,"sick", index);
                    }
                  }}
                  autoFocus={index === 0}
                  error={
                    props.errorIndices
                      ? props.errorIndices.includes(index)
                      : false
                  }
                  helperText={
                    props.errorIndices && props.errorIndices.includes(index)
                      ? "Please enter a number in between 0 and 24"
                      : ""
                  }
                  onKeyDown={props.onDown ? props.onDown : () => {}}
                />
                <InputLabel sx={{fontSize:'12px', marginBottom:'5px'}}>Sick</InputLabel>
              </Grid>
            </Grid>
            </CardContent>
            </Card>
          </ListItem>
        </Grid>
      ))}
      <br />
      <br />
    </List>
  );
}

TimeSheetFields.propTypes = {
  /**
   * function triggered when key is pressed while focus on a field, passes event e
   */
  onDown: PropTypes.func,
  /**
   * function called when hours value in a field is chaned, passes new value, date, index
   */
  updateHours: PropTypes.func,
  /**
   * object representing the timesheet that the field's form is under
   */
  timesheets: PropTypes.object.isRequired,
  /**
   * array of indices that have an invalid value in them or an error for them
   */
  errorIndices: PropTypes.arrayOf(PropTypes.number),
  /**
   * boolean indicating whether or not the timesheet has already been submitted
   */
  beenSubmitted: PropTypes.bool,
  /**
   * number of days per row for the form to generate
   */
  daysPerRow: PropTypes.number,
  /**
   * object used to indicate start and end dates of project / resource's term on said project
   * follows {startDates : [array of dates], endDates: [
   */
  boundaryDates: PropTypes.object,
  /**
   * function that determines if a day is disabled
   */
  isDayDisabled: PropTypes.func.isRequired,
};

TimeSheetFields.defaultProps = {
  beenSubmitted: false,
  daysPerRow: 7,
};

export default TimeSheetFields;
